import { Component } from '@angular/core';
import { windowToggle } from 'rxjs/operators';
import { FullValuePigService } from './services/full-value-pig.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    constructor(private fullValuePigService: FullValuePigService) { }

    ngOnInit() {
        /**  
         this.fullValuePigService.getLoadClientToken().subscribe(response => {
            this.fullValuePigService.loadClientToken =response;
            if(response.UserName.length==0)
            {
                this.fullValuePigService.redirectToLanding();
            }
        });
        */
    }

}
