import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

// -- Services --
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }

    canActivate() {
        return true;
    }
}
