import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountMetadataUnit, LoadClientToken, PFLitePaymentGrid } from 'src/app/classes/entity/load-client-token';
import { PFLitePaymentGridDetail } from 'src/app/classes/entity/pf-lite-payment-grid';
import { PFLiteBatch, IBatchData, ICloseOutData, IPFLiteCarcass } from 'src/app/classes/entity/pf-lite-data';
import { PerformanceFeedProgram } from 'src/app/classes/entity/performance-feed-program';

import { PerformanceGrowthADFI } from 'src/app/classes/entity/performance-growth-adfi';
import { AbstractService } from 'src/app/shared/services/abstract.service';


@Injectable({
  providedIn: 'root'
})

export class FullValuePigService extends AbstractService {

  loadClientToken: LoadClientToken;
  urlPath: string = '';

  constructor(private http: HttpClient) { super(); }

  async getLoadClientToken(): Promise<LoadClientToken> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), 'withCredentials': true
    };
    return await this.http.get<LoadClientToken>(this.apiPath + 'loadClientToken', httpOptions).toPromise().then((response) => {
      this.loadClientToken = new LoadClientToken();
      this.loadClientToken.setData(response);
      if (this.loadClientToken.UserName.length == 0) {
        this.redirectToLanding();
        return;
      }
      return this.loadClientToken;
    });
  }


  redirectToSigout() {
    window.location.href = this.loadClientToken.SignOutURL;
  }

  redirectToLanding() {
    window.location.href = this.loadClientToken.SpeciesURL;
  }

  throwError(errorResp: HttpErrorResponse) {
    if (errorResp.error.ExceptionMessage == 'Access denied, please log on first.') {
      this.redirectToLanding();
    } else {
      console.log('Get Response Error : ', errorResp);
    }
  }


  getFeedProgram(performanceID: number): Observable<Array<PerformanceFeedProgram>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.get<Array<PerformanceFeedProgram>>(this.apiPath + 'getfeedprogram?performanceID=' + performanceID, httpOptions);
  }

  getGrowthADFI(performanceID: number): Observable<PerformanceGrowthADFI> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.get<PerformanceGrowthADFI>(this.apiPath + 'getgrowthadfi?performanceID=' + performanceID, httpOptions);
  }

  getMasterMetadataUnitByAccount(accountID: number): Observable<Array<AccountMetadataUnit>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<Array<AccountMetadataUnit>>(this.apiPath + 'getmastermetadataunitbyaccount', accountID, httpOptions)
  }

  getPFLitePaymentGrid(accountID: number, weightUnitID: number): Observable<Array<PFLitePaymentGrid>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    var payload = { accountID: accountID, weightUnitID: weightUnitID };
    return this.http.post<Array<PFLitePaymentGrid>>(this.apiPath + 'getpflitepaymentgrid', payload, httpOptions);
  }

  getPFLitePaymentGridDetail(paymentGridID: number): Observable<Array<PFLitePaymentGridDetail>> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<Array<PFLitePaymentGridDetail>>(this.apiPath + 'getpflitepaymentgriddetail', paymentGridID, httpOptions);
  }

  createPFLitePaymentGridWhole(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<any>(this.apiPath + 'CreatePFLitePaymentGridWhole', payload, httpOptions);
  }

  savePFLitePaymentGridWhole(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<any>(this.apiPath + 'SavePFLitePaymentGridWhole', payload, httpOptions);
  }

  deletePFLitePaymentGrid(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.post<any>(this.apiPath + 'SavePFLitePaymentGridMaster', payload, httpOptions);
  }

  getPFLiteEntryBatch(accountID: number): Observable<Array<PFLiteBatch>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    return this.http.get<Array<PFLiteBatch>>(this.apiPath + 'getpfliteentrybatch?accountID=' + accountID, httpOptions);
  }

  savePFLiteDataEntry(batchData: IBatchData, closeOutData: ICloseOutData, lstPFLiteCarcass: Array<IPFLiteCarcass>): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      'withCredentials': true
    };
    var payload = { batchData: batchData, closeOutData: closeOutData, lstPFLiteCarcass: lstPFLiteCarcass };
    return this.http.post<any>(this.apiPath + 'savepflitedataentry', payload, httpOptions);
  }

}