import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// -- Application Modules --
import { ServiceModule } from '../services/service.module';

// -- Components --
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { NumPipeComponent } from './num-pipe/num-pipe.component';

import { HomeComponent } from './header/home.component';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';


@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        NumPipeComponent,
        HomeComponent,
        LoaderComponent
    ],
    imports: [
        // Angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,

        // Application Modules
        ServiceModule,
        RouterModule,
        HttpClientModule,
        TranslateModule
    ],
    exports: [
        // Angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        TranslateModule,


        // Application Modules
        ServiceModule,

        // Components
        HeaderComponent,
        FooterComponent,
        NumPipeComponent,
        HomeComponent,
        LoaderComponent
    ]
})
export class SharedModule { }

