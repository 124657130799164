import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FullValuePigService } from 'src/app/services/full-value-pig.service';

// -- Constants --
import { bgTop, elancoLogo, fvpLogo } from '../../constants';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input() homeMenu = false;
    @Input() pwdMenu = false;
    @Input() logoutMenu = false;


    backgroundImg: string = bgTop;
    elancoLogo: string = elancoLogo;
    fvpLogo: string = fvpLogo;
    loginUser: string;

    constructor(private router: Router, private fullValuePigService: FullValuePigService) { }

    ngOnInit() { }

    home() {
        this.fullValuePigService.redirectToLanding();
    }

    // Logout function
    logout() {
        this.fullValuePigService.redirectToSigout();
    }

    // Navigate function
    navigate(url: string) {
        this.router.navigateByUrl(url);
    }

}
