import { Component, OnInit, Input } from '@angular/core';

// -- Constants --
import { bgBottom, eksLogo } from '../../constants';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    currentYear: number = new Date().getFullYear();

    @Input() copyright = false;

    backgroundBottom: string = bgBottom;
    eksLogo: string = eksLogo;

    constructor() { }

    ngOnInit() {
    }

}
