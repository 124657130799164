import { Component, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FullValuePigService } from 'src/app/services/full-value-pig.service';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    @Input() pageSummary: any;
    @Output() export_warn_message = new EventEmitter();

    zoomLevels = [0.05, 0.1, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.5, 0.54, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1, 1.05, 1.10, 1.15, 1.20, 1.25, 1.30, 1.35, 1.40, 1.45, 1.5, 1.55, 1.60, 1.65, 1.70, 1.75, 1.80, 1.85, 1.90, 1.95, 2];
    currentZoomLevel: number = this.zoomLevels[19];
    panZoomController;
    pageTitle: string = '';
    dataZoom: boolean = false;
    languageSelectDropDown: boolean = false;
    isFullScreen: boolean = false;
    backNavigation: boolean = false;
    elementId: string = '';

    elem = document.documentElement;

    languages = [
        { "languageCode": 'en', "languageName": 'English' },
        { "languageCode": 'es', "languageName": 'Spanish' },
        { "languageCode": 'zh', "languageName": 'Chinese' },
        { "languageCode": 'ms', "languageName": 'Malaysian' },
        { "languageCode": 'fr', "languageName": 'French' },
        { "languageCode": 'de', "languageName": 'Germany' },
        { "languageCode": 'nl', "languageName": 'Dutch' },
        { "languageCode": 'pt', "languageName": 'Portugues' },
        { "languageCode": 'ru', "languageName": 'Russian' }
    ]

    constructor(private router: Router, public translateService: TranslateService, private fullValuePigService: FullValuePigService) {
        translateService.setDefaultLang('en');
    }

    ngOnInit() {
        //this.translateService.currentLang = 'en';
        this.pageTitle = this.pageSummary.pageTitle;
        this.dataZoom = this.pageSummary.dataZoom;
        this.languageSelectDropDown = this.pageSummary.languageSelectDropDown;
        this.elementId = this.pageSummary.elementId;
        this.backNavigation = this.pageSummary.backNavigation;
    }


    switchLang(lang: string) {
        this.translateService.use(lang);
    }

    redirectToHome() {
        this.fullValuePigService.redirectToLanding();
    }

    openFullscreen() {
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        }
        this.isFullScreen = true;
    }

    closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
        this.isFullScreen = false;
    }

    @HostListener('document:fullscreenchange', ['$event'])
    exitHandler(event) {
        if (!document.fullscreenElement) {
            this.isFullScreen = false;
        }
    }
}