import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class AbstractService {

	public apiPath = "";

	constructor() {
		this.getEnvironment();
	}

	getEnvironment(): string {
		if (document.URL.search('localhost') !== -1) {
			this.apiPath = environment.localData;
		} else if (document.URL.search('fvpwbde-swine-eks-solutions-d') !== -1 ||
			document.URL.search('fvpwbde.swine.solutions-d') !== -1) {
			this.apiPath = environment.devData;
		} else if (document.URL.search('fvpwbde-swine-eks-solutions-q') !== -1 ||
			document.URL.search('fvpwbde.swine.solutions-q') !== -1) {
			this.apiPath = environment.qaData;
		} else {
			this.apiPath = environment.prodData;
		}
		return this.apiPath;
	}
}