export interface LitePaymentGrid {
  ID: number;
  Name: string;
  AccountID: number;
  WeightType: number;
  PriceType: number;
}

export interface AccountList {
  id: number;
  name: string;
  defaultCOAName: string;
  weightUnitID: number;
  weightUnitName?: any;
  currencyUnitID: number;
  currencyUnitName?: any;
  symbol?: any;
}

export interface Feature {
  FeatureID: number;
  IsValid: boolean;
  AccountList: AccountList[];
  AccountIDs: number[];
}



export interface PerformanceList {
  performanceParameterID: number;
  performanceParameterName: string;
  accountId: number;
  periodMarketing: number;
  carcassBasePrice: number;
  cullPercentofBaseADG: number;
  mortalityofBaseADG: number;
  cullPercentofBaseADFI: number;
  mortalityofBaseADFI: number;
  pigCost: number;
  deadLivePrice: number;
  nonAmbulatoryLivePrice: number;
  cullsLivePrice: number;
  overWeekPostWeanADG: number;
  overWeekPostWeanADFI: number;
  isDefault: boolean;
  isDeleted: boolean;
  createID: number;
  changedID: number;
}




export interface AccountMetadataUnit {
  FieldUnit: string,
  DataObject: string,
  UnitID: number,
  UnitType: string,
  AliasName: string
}


export interface ItemCode {
  Code: string,
  Name: string
}

export interface PFLitePaymentGrid {
  AccountID: number,
  LastModifyDate: LastModifyDate,
  WeightType: number,
  CreateUser: CreateUser,
  DisableFlag: DisableFlag,
  LastModifyUser: LastModifyUser,
  ID: number,
  WeightUnitID: WeightUnitID,
  PriceType: number,
  Name: string
}

export interface LastModifyDate {
  'xsi:nil': XsiNill
}

export interface CreateUser {
  'xsi:nil': XsiNill
}

export interface DisableFlag {
  'xsi:nil': XsiNill
}

export interface LastModifyUser {
  'xsi:nil': XsiNill
}

export interface WeightUnitID {
  'xsi:nil': XsiNill
}

export interface XsiNill {
  'xsi:nil': boolean
}

export class LoadClientToken {

  ContactID!: number;
  UserName!: string;

  PHEFeature!: Feature;
  //LastActivitySubject!: string;
  PerformanceList!: PerformanceList[];
  UserLocaleType!: string;

  SpeciesURL!: string;
  SignOutURL!: string;
  constructor() { }

  setData(loadClientToken: LoadClientToken) {
    this.UserName = loadClientToken.UserName;
    this.ContactID = loadClientToken.ContactID;

    this.PHEFeature = loadClientToken.PHEFeature;
    //this.LastActivitySubject = loadClientToken.LastActivitySubject;
    this.PerformanceList = loadClientToken.PerformanceList;
    this.UserLocaleType = loadClientToken.UserLocaleType;

    this.SpeciesURL = loadClientToken.SpeciesURL;
    this.SignOutURL = loadClientToken.SignOutURL;
  }
}
