// -- Background Images --
export const bgTop: string = 'assets/images/swine_bg_top.jpg';
export const bgBottom: string = 'assets/images/swine_bg_bot.jpg';

// -- Logo Icons --
export const elancoLogo: string = 'assets/images/logo_elanco_122x60.png';
export const fvpLogo: string = 'assets/images/logo_fvp.png';
export const eksLogo: string = 'assets/images/logo_eks_270x50.png';
export const knowledSolutionsLogo: string = 'assets/images/logo-EKS.png';

// -- Sorting Icons --
export const ascIcon = 'assets/images/sortUp.gif';
export const descIcon = 'assets/images/sortDown.gif';

// -- Funcationality Icons --
export const editIcon = 'assets/images/edit.png';
export const deleteIcon = 'assets/images/delete.png';
export const historyIcon = 'assets/images/history.png';
