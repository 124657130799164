import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// -- Routing --
import { AppRoutingModule } from './app-routing.module';

// -- Components --
import { AppComponent } from './app.component';
import { FullValuePigService } from './services/full-value-pig.service';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpInterceptors } from './services/http-interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]

            }
        })
    ],
    providers: [
        //FullValuePigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (fullValuePigService: FullValuePigService) => async () => {
                fullValuePigService.getEnvironment();
                await fullValuePigService.getLoadClientToken();
                if (fullValuePigService.loadClientToken.UserName.length == 0) {
                    fullValuePigService.redirectToLanding();
                }
            },
            deps: [FullValuePigService],
            multi: true
        },
        DatePipe, { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptors, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}