import { NgModule } from '@angular/core';
import { LoaderComponent } from '../shared/loader/loader.component';
import { NgxSpinnerService } from "ngx-spinner";

// -- Services --
import { AuthService, AuthGuardService } from './index';


@NgModule({
    providers: [
        AuthService,
        AuthGuardService,
        LoaderComponent,
        NgxSpinnerService
    ]
})

export class ServiceModule { }
