import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, Response } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { timer } from 'rxjs';


const httpOptions = {
    headers: new Headers({
        'Content-Type': 'application/json'
    })
};

@Injectable()
export class AuthService {

    constructor(
        private http: Http,
        private router: Router
    ) { }


    // Handling error
    handleError(error: Response | any) {
        console.log(error);
    }

    // Redirect to home
    redirectToHome() {
        this.router.navigateByUrl('/swine');
    }

}